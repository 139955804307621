$textColor = #444
$textColorDark = darken($textColor, 4)

$backgroundColor = #f0f0f0
$accentColor = red

$borderColor = #d0d0d0
$borderColorLight = lighten($borderColor, 90)

$menuWidth = 240px
$auctionHeightDesktop = 164px
$auctionHeightMobile = 215px

$successColor = green
$yellowColor = #ff0

$box_shadow = $borderColor 4px 4px 0px 0px
$smooth_transition = 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms

$qb_color = tint(red, 25%)
$wr_color = tint(orange, 25%)
$rb_color = tint(green, 25%)
$te_color = tint(blue, 25%)
$k_color = tint(grey, 25%)
$dst_color = tint(black, 25%)

$qbrbwrte_color = tint(red, 25%)
$rbwrte_color = tint(purple, 25%)
$rbwr_color = tint(yellow, 25%)

// $red = #e6194B
$red = rgb(255, 80, 0)
$orange = #f58231
$yellow = #ffe119
$pink = #fabed4
// $green = #3cb44b
$green = rgb(0, 200, 5)
$cyan = #42d4f4
$blue = #4363d8
$navy = #000075
$magenta = #f032e6
$purple = #911eb4
$lavender = #dcbeff
$mint = #aaffc3
$maroon = #800000
$brown = #9A6324
$beige = #fffac8
$grey = #a9a9a9
$lime = #bfef45
