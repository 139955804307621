.table__container
  width 100%
  overflow-x auto

.table__row
  width max-content
  min-width 100%
  position relative
  display flex
  align-items stretch
  background white

@media (pointer:fine)
  .table__body .table__row:hover
    background #fafafa
    .table__cell.lead-cell
      background #eaeaea
    .player__item-menu
      display flex

.table__head
  align-items center
  height 64px
  border-bottom 1px solid rgba(224, 224, 224, 1)
  font-size 11px
  text-transform uppercase
  font-weight 500
  align-items stretch

.table__cell
  flex 0 0 60px
  width 60px
  min-height 24px
  display flex
  align-items center
  padding 4px 6px
  &.metric
    justify-content flex-start

  &.text
    width 150px
    flex 1 0 150px
    overflow hidden
    text-overflow ellipsis
    white-space nowrap

  &.wide_cell
    width 90px
    flex 0 0 90px

  &.lead-cell
    background white
    border-right 1px solid #f0f0f0
    padding 0 4px 0 4px
    border-bottom 1px solid #e8e8e8

.table__cell-text
  text-overflow ellipsis
  overflow hidden
  white-space nowrap
  min-width 48px
  line-height 1.43

.table__head .table__cell
  height 100%
  font-size 10px
  font-weight 600
  text-transform capitalize
  line-height 1.43

.table__row:last-child .table__cell
  border-bottom none

.table__row-summary
  background #efefef
  border-bottom 1px solid rgba(224, 224, 224, 1)
  .table__cell.sticky__column
    background #efefef
    justify-content flex-end
    font-weight 600
    padding 6px 24px 6px 16px

.table__divider
  background #e0e0e0
  display flex
  justify-content center
  font-size 9px
  text-transform uppercase
  padding 2px 0

.header__row
  background #eee
  color rgb(0,0,0)
  font-weight 700
  padding 4px 3px
  font-size 11px
  font-family 'IBM Plex Mono'

.sticky__column
  position sticky
  z-index 1
  left 0
  &.table__cell
    background rgb(248,248,248)
  &.sticky__two
    left 60px
  &.header__row
    z-index 2

.row__group
  flex 0 0 auto
  display inline-flex
  flex-direction column
  justify-content center
  align-items center
  position relative
  + .row__group
  + .table__cell
      border-left 1px solid $borderColor

.row__group-head
  font-family 'IBM Plex Mono', monospace
  font-weight 800
  flex 0 0 auto
  text-transform uppercase
  font-size 11px
  padding 2px
  border-bottom 1px solid $borderColor
  width 100%
  text-align center
  height 24px
  display flex
  align-items center
  justify-content center
  letter-spacing 1px

.row__group-body
  flex 1
  display flex
  justify-content space-evenly
  align-items stretch
  width 100%

.section-header-title
  text-transform uppercase
  text-weight 600
  font-size 14px
  color lighten($textColor, 40%)
  padding 16px 0

@media (min-width 600px)
  .table__cell.lead-cell
    flex 0 0 250px
    width 250px

@media (max-width 599px)
  .table__cell.lead-cell
    flex 0 0 25vw
    width 25vw
    max-width 250px