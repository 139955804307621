.scroll
  overflow-x hidden
  overflow-y auto !important
  -webkit-overflow-scrolling touch

.cursor
  cursor pointer

.league-container
  padding 16px 16px 0
  max-width 1200px
  margin 0 auto

  &.large
    max-width 1200px

  &.full
    max-width 100%

p
  margin-top 16px
  font-family "Inter", "Helvetica", "Arial", sans-serif
  font-weight 400
  line-height 1.5
  letter-spacing 0.00938em

.MuiTooltip-popper .MuiTooltip-tooltip
  color rgba(0, 0, 0, 0.87)
  font-size 11px
  box-shadow $box_shadow
  border 1px solid $borderColor
  background-color #fff

.MuiTooltip-popper.multiline .MuiTooltip-tooltip
  font-size 12px
  padding 12px 16px

.text-green
  color $green
  font-weight 600

.text-red
  color $red
  font-weight 600
