.timer__time.metric
  position relative
  z-index 2
  font-size 30px

.timer__flash
  absolute left top right bottom
  display none
  animation blinker 1s linear infinite
  background lighten($accentColor, 70%)

.timer.warning .timer__flash
  display block

@keyframes blinker
  50%
    opacity 0

@media (max-width 400px)
  .timer__time.metric
    font-size 12px
