.release__select-player
  flex 1
  display flex
  align-items center

  .player__name-main .nfl__team
    margin-left 4px

.release__select-player-value
  flex 0 0 40px
  width 40px

.transition__bid-inputs
  display flex
  margin-top 16px
  flex-direction column
  min-width 350px
  .MuiFormControl-root
    margin-bottom 8px
