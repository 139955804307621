html
  height 100%
  width 100%
  overflow hidden
  text-size-adjust 100%
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility

body
  position relative
  font-family "Inter", "Helvetica", "Arial", sans-serif
  font-size 14px
  color $textColor
  height 100%
  width 100%
  overflow hidden
  // used as ios web app status bar background
  background-color #1976d2

main
#root
  height 100%
  background-color $backgroundColor

.initializing__backdrop.MuiBackdrop-root
  z-index 100001
  color white
