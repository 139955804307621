.selected-player-markets
  display flex
  flex-wrap wrap
  gap 10px
  max-width 1000px
  margin 0 auto
  padding 0 16px

  .MuiAutocomplete-root
    width 100%
    margin-top 10px

  .selected__table
    min-width 300px

  .table__cell
    &.source
      min-width 100px

    &.timestamp
      min-width 100px

.market-card
  width 100%
  min-width 300px