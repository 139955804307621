.player__headshot
  &.MuiAvatar-colorDefault
    background transparent
  &.square
    background rgb(230,230,230)
  &.QB
    border-left 3px solid $qb_color
  &.WR
    border-left 3px solid $wr_color
  &.RB
    border-left 3px solid $rb_color
  &.TE
    border-left 3px solid $te_color
  &.K
    border-left 3px solid $k_color
  &.DST
    border-left 3px solid $dst_color
