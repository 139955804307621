*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/**
 * Add a few more `block` elements, since they're mostly treated that way.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary,
audio,
canvas,
img,
video {
  display: block;
}

/**
 * Reset line-height, since it makes aligning elements way easier.
 */

html {
  line-height: 1;
}

/**
 * Remove default heading styles.
 */

/* h1, */
/* h2, */
/* h3, */
/* h4, */
/* h5, */
/* h6 { */
/*   margin: 0; */
/*   padding: 0; */
/*   font-size: inherit; */
/*   line-height: 1; */
/*   font-weight: inherit; */
/* } */

/**
 * Remove default link styles, since they get used for buttons, etc.
 */

a {
  color: inherit;
  text-decoration: none;
}

/**
 * Remove outline for links. You should style it, but not necessarily outline.
 */

a:focus,
:focus {
  outline: 0;
}

/**
 * Remove default paragraph styles.
 */

p,
small {
  margin: 0;
  padding: 0;
}

/**
 * Remove default blockquote and quote styles.
 */

blockquote,
q {
  margin: 0;
}

/**
 * Remove default cite styles.
 */

cite {
  font-style: inherit;
}

/**
 * Remove default form styles.
 */

form,
pre,
body,
figure {
  margin: 0;
}

img {
  border: 0;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input {
  -webkit-appearance: none;
}

input[type='checkbox'],
input[type='radio'] {
  padding: 0;
  box-sizing: border-box;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-spacing: 0;
  max-width: 100%;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  color: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}

button,
input,
select[multiple],
textarea {
  background-image: none;
}

a {
  text-decoration: none;
}

img {
  vertical-align: middle;
  max-width: 100%;
}
