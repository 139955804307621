.team__name
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
  padding 0 4px
  line-height 16px
  font-family 'IBM Plex Mono', monospace
  font-weight 900
  color $textColor

.team__color
  height 100%
  width 3px
  margin-right 4px

.MuiAlert-root .team__name
  display inline
