.base-TabsList-root.base-TabsList-horizontal
  display flex
  overflow-x scroll
  padding 4px
  background $backgroundColor
  &::before
  &::after
    content: ''
    margin: auto

  .base-Tab-root
    flex-shrink 0
    padding 16px 8px
    margin 6px 6px
    border-radius 5px
    display flex
    justify-content center
    align-items center
    cursor pointer
    font-size 12px
    font-weight bold
    border 0
    background $backgroundColor
    &.Mui-selected
    &.base--selected
      background white
      color $accentColor
    &:hover
      background lighten($backgroundColor, 60%)
      color $accentColor
