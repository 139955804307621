.timestamp
  display flex
  flex-wrap wrap
  font-size 12px
  color lighten($textColor, 20%)

.timestamp__date
.timestamp__time
  flex 1
  margin 0px 4px
  display flex
  align-items center
  white-space nowrap
  font-variant-numeric tabular-nums
