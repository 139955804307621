.auction__team
  flex 1
  display flex
  flex-direction column
  align-items center
  position relative
  padding 16px 0 8px
  font-size 12px
  font-weight 900
  + .auction__team
    border-left 1px solid #f0f0f0

  &.offline
    font-weight 400

  &.nominating
    background #f0f0f0
    &:before
      absolute top left right
      content 'nominating'
      text-transform uppercase
      font-size 8px
      display flex
      justify-content center
      padding-top 4px
      color lighten($textColor, 20%)

  &.winning .auction__team-bid
    background $accentColor
    color white

  &.owner
    font-weight 700
    background lighten(green, 93%)

.auction__team-bid
  absolute left bottom -22px right
  background lighten($accentColor, 90%)
  padding 4px
  display flex
  justify-content center
  font-size 11px

.auction__team-name
  overflow hidden
  white-space nowrap
  text-overflow ellipsis

.auction__team-cap
  font-family 'IBM Plex Mono', monospace
  font-size 14px

.auction__team-roster-space
  font-size 10px
  font-weight 300
  text-transform uppercase
  margin-top 4px

@media (max-width 800px)
  .auction__team
    min-width 60px
