.nominated__player
  display flex
  flex 1

  .player__headshot
    border-radius 0

.auction__nominated-player
  flex 1
  display flex
  .selected__player-header-item
    min-width 0
    flex 1 1 0

.nominated__player-details
  flex 1
  display flex
  padding-top 8px
  font-size 12px
  label
    font-size 10px
    font-family "Inter", "Helvetica", "Arial", sans-serif;
    font-weight 300
    text-transform uppercase
    display block
    padding-bottom 6px

.nominated__player-detail
  flex 0 0 auto
  + .nominated__player-detail
    padding-left 16px

@media (max-width 799px)
  .auction__nominated-player .player__name-headshot
    overflow hidden
    position absolute
    background rgba(255,255,255,0.8)
    border 1px solid $borderColor
    border-radius 50%
    width 100px
    height 100px
    display flex
    align-items flex-end
    justify-content center
    top -96px
    left 8px

@media (max-width 500px)
  .auction__nominated-player .selected__player-header-item
    padding 4px
    flex 0 0 auto
    min-width 24px
