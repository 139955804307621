.menu
  a
    text-decoration none
    min-width 45px
    padding 5px 10px
    vertical-align top
    position relative
    font-size 14px
    text-transform capitalize
    color rgba(50,50,50,0.9)
    border-radius $borderRadius
    display inline
    padding 4px 8px
    margin 6px 6px
    border-radius 5px
    &:hover
      background lighten($backgroundColor, 60%)
      color $accentColor
    &.active
      background white
      color black
      font-weight 700
