button:not(.MuiButton-root):not(.MuiIconButton-root):not(.MuiFab-root):not(.MuiButtonBase-root):not(.base-Tab-root)
.button:not(.MuiButton-root):not(.MuiIconButton-root):not(.MuiFab-root):not(.MuiButtonBase-root)
  outline none
  transform translate(0, 0)
  position relative
  border 1px solid rgba(0, 0, 0, 0.87)
  border-radius 0.15em
  padding 0 15px
  height 36px
  display inline-flex
  align-items center
  justify-content center
  background transparent
  background-image none
  min-width 30px
  vertical-align middle
  text-align center
  color rgba(0, 0, 0, 0.87)
  transition background-color $smooth_transition,box-shadow $smooth_transition,border $smooth_transition
  border-radius 4px
  font-family 'Inter', sans-serif
  font-size .875rem
  font-weight 500
  letter-spacing .0892857143em
  text-transform uppercase
  &:hover:not(:disabled)
  &:hover:not([disabled])
    background-color rgba(0, 0, 0, 0.08)
  &:disabled
  &[disabled]
    opacity .4
    user-select none

  + button
  + .button
    margin-left 5px

  .count
    position absolute
    top 3px
    right 5px
    font-size 8px
    border-radius 99em
    padding 3px
    line-height 5px
    margin 0
    font-family monospace
    border-left 1px solid rgba(0, 0, 0, 0.23)
    background rgba(255,255,255,0.6)
    border 0

  &.button__outline
    border 1px solid rgba(0, 0, 0, 0.87)

  &.button__text
    border 0

  &.button__success:not(.button__icon)
    color white
    border-color $successColor
    background-color $successColor

  &.button__success.button__icon
    fill $successColor

  &.button__failure
    border-color $failureColor
    background-color $failureColor

  &.active
  &.button__active
    color $accentColor

  &.button__floating
    position absolute
    right 16px
    bottom 16px
    background $accentColor
    fill white
    width 56px
    height 56px
    z-index 999999
    box-shadow rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px
    &:hover
      background-color lighten($accentColor, 15%)
      box-shadow rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px

  &.button__small
    padding 0 8px
    height 24px
    font-size 12px
